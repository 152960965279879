import firebase from 'firebase/app';
import 'firebase/storage';

const getAvatarRef = userId => {
	return `images/avatars/avatar_${userId}.jpg`;
};

export default {
	/** @param {{ userId: t.User['docId'], file: string | File }} param */
	uploadAvatarImg: async ({ userId, file }) => {
		if (file instanceof File) {
			const avatarRef = firebase.storage().ref(getAvatarRef(userId));
			await avatarRef.put(file);
			// const link = `${avatarRef.bucket}/o/${encodeURIComponent(avatarRef.fullPath)}`;
			// const url = `https://firebasestorage.googleapis.com/v0/b/${link}?alt=media`;
			// return url;
			return avatarRef.getDownloadURL();
		}
		if (typeof file === 'string' && file.startsWith('data:image')) {
			const avatarRef = firebase.storage().ref(getAvatarRef(userId));
			await avatarRef.putString(file, 'data_url');
			// const link = `${avatarRef.bucket}/o/${encodeURIComponent(avatarRef.fullPath)}`;
			// const url = `https://firebasestorage.googleapis.com/v0/b/${link}?alt=media`;
			// return url;
			return avatarRef.getDownloadURL();
		}

		return file;
	}
};
