<script>
import Pagination from '@/components/UIs/Pagination.vue';
import followApi from '@/apis/followApi';
import { globalState } from '@/stores/global';
import router from '@/router';
import { computed, reactive, ref, watchEffect } from 'vue';
import DefaultAvatar from '@/assets/icons/DefaultAvatar.vue';
import { useFollowingUser, useFollowings, useNumbersOfLikesByUser, usePosts, useUser, useFollowers, useFavorites, usePagination } from '@/hooks/query';
import { fDefined, showError } from '@/services/utils';
import { queryClient } from '@/main';
import EditMyPage from './EditMyPage.vue';

const PER_PAGE = 12;
export default {
	components: { EditMyPage, DefaultAvatar, Pagination },
	setup() {
		const state = reactive({
			showingEditModal: false,
			toggleFollowUserActionSubmiting: false
		});

		/** @type {t.User['docId']} */
		const userId = (router.currentRoute.value.params.userId);

		const isLoggedInUser = computed(() => {
			return userId === globalState.user.id;
		});
		const userQuery = useUser(userId);
		const isFollowingPostUser = useFollowingUser(userId);
		const numbersOfLikesByUserQuery = useNumbersOfLikesByUser(userId);
		const postsQuery = usePosts(userId);
		const followingsQuery = useFollowings(userId);
		const followersQuery = useFollowers(userId);
		const favoritesQuery = useFavorites(userId);
		watchEffect(() => {
			// if not found
			if (userQuery.status.value === 'success' && !userQuery.data.value?.docId) {
				router.replace({ name: 'error_404' });
			}
		});

		const toggleFollowUserAction = async () => {
			if (state.toggleFollowUserActionSubmiting) return;
			try {
				state.toggleFollowUserActionSubmiting = true;
				await followApi.toggleFollowUser({
					user_id: fDefined(globalState.user.id),
					follow_user_id: userId
				});
				queryClient.invalidateQueries('is_follow');
			} catch (error) {
				console.log(error);
				showError();
			} finally {
				state.toggleFollowUserActionSubmiting = false;
			}
		};

		const { visibleList, listLength } = usePagination(
			computed(() => {
				return postsQuery.data.value?.filter(p => p?.undisplay_flag !== 1 || p?.user_id === globalState.user?.id) || [];
			}),
			{ PER_PAGE }
		);
		return {
			state,
			pageUser: userQuery.data,
			refetchPageUser: userQuery.refetch,
			isFollowingPostUser: isFollowingPostUser.query.data,
			isFollowingPostUserStatus: isFollowingPostUser.query.status,
			numbersOfLikesByUser: numbersOfLikesByUserQuery.data,
			posts: postsQuery.data,
			followings: followingsQuery.data,
			followers: followersQuery.data,
			favorites: favoritesQuery.data,
			globalState,
			toggleFollowUserAction,
			openEditModal: () => {
				state.showingEditModal = true;
			},
			closeEditModal: () => {
				state.showingEditModal = false;
			},
			// computed
			isLoggedInUser,
			listLength,
			visibleList,
			CONFIG: {
				PER_PAGE
			}
		};
	}
};
</script>

<template>
	<div class="flex flex-1 p-4 text-base">
		<div class="w-full lg:mx-8">
			<div class="flex flex-row items-center justify-between mb-4 lg:mb-32px lg:mt-49px">
				<div class="w-full">
					<div class="flex flex-row items-center mb-4 md:mb-0 lg:mr-0">
						<div class="mr-15px">
							<div class="items-center mb-2">
								<div class="w-20 h-20 lg:w-32 lg:h-32">
									<img v-if="pageUser?.icon_img" :src="pageUser?.icon_img" alt="User Avatar" class="object-cover w-full h-full rounded-full " />
									<div v-if="pageUser?.user_type === 2">
										<img src="@/assets/images/supporter.png" alt="supporter" class="w-full h-full" />
									</div>
									<div v-if="pageUser?.user_type === 3" class="text-asp-FFC0CB text-center">
										<img src="@/assets/images/special-supporter.png" alt="supporter" class="w-full h-full" />
									</div>
									<default-avatar v-if="!pageUser?.icon_img" />
								</div>
							</div>
						</div>
						<div class="flex flex-col justify-center flex-1">
							<p class="text-xl tracking-wider lg:text-2xl break-words word-break whitespace-pre-line">
								{{ pageUser?.nickname }}
							</p>
							<div class="flex flex-row items-center hidden mt-0 lg:mt-4 lg:flex">
								<img src="@/assets/images/heart-white-icon.png" alt="like count icon" class="w-8 mr-2" />
								<p class="text-base">いいねした数：{{ numbersOfLikesByUser }}</p>
							</div>
						</div>
						<button v-if="isLoggedInUser" class="focus:outline-none sm:mt-0 w-140px lg:w-auto" type="button" @click="openEditModal">
							<img src="@/assets/images/edit-profile-button.png" alt="follow button" class="w-full h-full" />
						</button>
						<div v-if="!isLoggedInUser && isFollowingPostUserStatus === 'success'">
							<button
								v-if="isFollowingPostUser"
								class="flex items-center justify-center mx-auto text-base font-extrabold text-white bg-center bg-no-repeat bg-contain lg:text-xl md:h-24 bg-button-blue lg:w-300px hover:bg-button-yellow focus:outline-none hover:text-blue-400 w-140px h-50px"
								@click="toggleFollowUserAction"
							>
								フォロー中
							</button>
							<button
								v-if="!isFollowingPostUser"
								class="flex items-center justify-center mx-auto text-base font-extrabold text-blue-600 bg-center bg-no-repeat bg-contain lg:text-xl md:h-24 bg-button-yellow lg:w-300px hover:bg-button-blue focus:outline-none hover:text-white w-140px h-50px"
								@click="toggleFollowUserAction"
							>
								フォローする
							</button>
						</div>
					</div>
					<div class="flex flex-row items-center lg:hidden lg:mt-6 sm:mt-0">
						<img src="@/assets/images/heart-white-icon.png" alt="like count icon" class="w-8 mr-2" />
						<p class="text-base">いいねした数：{{ numbersOfLikesByUser }}</p>
					</div>
				</div>
				<!-- ANCHOR edit profile button  -->

				<!-- ANCHOR - Follow Action -->
			</div>
			<div>
				<p class="mb-8 text-base break-words word-break whitespace-pre-line">
					{{ pageUser?.greeting }}
				</p>
				<div v-if="isLoggedInUser" class="flex flex-row items-center justify-between mb-15px">
					<router-link
						:to="{ name: 'follow_list' }"
						type="button"
						class="px-4 py-2 mb-5 text-xs text-white rounded-full sm:text-base focus:outline-none bg-asp-orange lg:min-w-168px sm:mb-0 appearance-none"
					>
						フォロー中 : {{ followings?.length || 0 }}
					</router-link>
					<router-link
						:to="{ name: 'follower_list' }"
						type="button"
						class="px-4 py-2 mb-5 text-xs text-white rounded-full sm:text-base focus:outline-none bg-asp-orange lg:min-w-168px sm:mb-0 appearance-none"
					>
						フォロワー: {{ followers?.length || 0 }}
					</router-link>
					<router-link
						:to="{ name: 'favorite' }"
						type="button"
						class="px-4 py-2 mb-5 text-xs text-white rounded-full sm:text-base focus:outline-none bg-asp-orange lg:min-w-168px sm:mb-0 appearance-none"
					>
						お気に入り: {{ favorites?.length || 0 }}
					</router-link>
				</div>
			</div>

			<div class="flex flex-row justify-end mb-14px">
				<div>じぶんの絵（ピース）の数:</div>
				<div class="ml-2">{{ posts?.length }}</div>
			</div>
			<pagination v-if="listLength" :total-record="listLength" :per-page="CONFIG.PER_PAGE" class="mb-8" />
			<div v-if="visibleList.length">
				<div class="grid grid-cols-3 mb-8 gap-1px ">
					<router-link v-for="post in visibleList" :key="post.docId" :to="{ name: 'posts_detail', params: { id: post.docId } }">
						<div class="bg-gray-100 relative aspect-w-1 aspect-h-1">
							<div class="flex justify-center items-center">
								<div class="relative w-full h-full">
									<span
										v-if="post?.undisplay_flag === 1 && post?.user_id === globalState.user?.id"
										class="z-10 text-base md:text-2xl text-white absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
										>非公開中</span
									>
									<div
										:alt="post.post_title"
										:style="{ backgroundImage: `url(${post.post_img})` }"
										:class="[
											post?.undisplay_flag === 1
												? 'filter grayscale blur-sm w-full h-full bg-contain bg-no-repeat bg-center bg-asp-black'
												: 'w-full h-full bg-contain bg-no-repeat bg-center bg-asp-black'
										]"
									></div>
								</div>
							</div>
						</div>
					</router-link>
				</div>
			</div>
			<pagination v-if="listLength" :total-record="listLength" :per-page="CONFIG.PER_PAGE" />
		</div>
	</div>
	<edit-my-page v-if="state.showingEditModal" @close-edit-modal="closeEditModal"></edit-my-page>
</template>
<style>
.word-break {
	word-break: break-word;
}
</style>
