<template>
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 62.37 62.38">
		<title>Asset 2</title>
		<g id="Layer_2" data-name="Layer 2">
			<g id="Layer_1-2" data-name="Layer 1">
				<path
					class="cls-1"
					d="M29.33,0H33l.22,0a26,26,0,0,1,4.12.54A30.63,30.63,0,0,1,55,11.09,30.67,30.67,0,0,1,62.16,35,28.76,28.76,0,0,1,60,43.13,30.93,30.93,0,0,1,45.68,58.79a30.51,30.51,0,0,1-16.51,3.53,28.63,28.63,0,0,1-6.56-1.14A31,31,0,0,1,3.44,45.46,29.57,29.57,0,0,1,.29,35.64C.16,34.77.09,33.89,0,33V29.33c0-.09,0-.19,0-.28a28,28,0,0,1,.75-5A30.91,30.91,0,0,1,12.94,5.87,30.41,30.41,0,0,1,27.15.23C27.88.14,28.6.08,29.33,0m1.84,3.94A27.24,27.24,0,1,0,58.41,31.17,27.28,27.28,0,0,0,31.17,3.94"
				/>
				<path
					class="cls-1"
					d="M46.82,48.61h-1.4c-.28,0-.39-.1-.4-.39a13.45,13.45,0,0,0-5.37-10.54,13.18,13.18,0,0,0-7.21-2.81,13.59,13.59,0,0,0-11,3.88,13.23,13.23,0,0,0-4,7.86,10.41,10.41,0,0,0-.12,1.58c0,.3-.1.42-.41.42H14.15c-.37,0-.43-.06-.4-.43A17.12,17.12,0,0,1,23.69,33c.56-.28.56-.27.06-.65A11.82,11.82,0,0,1,19,23.77a12.21,12.21,0,0,1,22.8-7,11.52,11.52,0,0,1,1.53,6.47,11.93,11.93,0,0,1-5,9.22c-.26.19-.26.19.07.34a17.21,17.21,0,0,1,9.8,12.1,16.38,16.38,0,0,1,.38,3.35c0,.22-.08.33-.31.32ZM31.17,14.13a8.58,8.58,0,1,0,8.64,8.58,8.57,8.57,0,0,0-8.64-8.58"
				/>
			</g>
		</g>
	</svg>
</template>

<style>
.cls-1 {
	fill: #231916;
}
</style>
