<script>
import { markRaw, reactive, watchEffect } from 'vue';
import { number, object, string } from 'yup';
import { useField, useForm } from 'vee-validate';
import { globalState, globalActions } from '@/stores/global';
// prevent error bind :src="@/assets/images/sample-user-avatar.png"
import defaultAvatarImg from '@/assets/images/sample-user-avatar.png';
import userApi from '@/apis/userApi';
import ngWordApi from '@/apis/ngWordApi';
import storageApi from '@/apis/storageApi';
// eslint-disable-next-line import/named
import { USER } from '@/constants/index';
import CropModal from '@/components/ImageCropper/CropModal.vue';
import { usePrefectures } from '@/hooks/query';
import { f } from '@/services/utils';
import { queryClient } from '@/main';

const useEditMyPageForm = () => {
	const initialValues = markRaw({
		user_type: f(globalState.user.user_type),
		name: f(globalState.user.name),
		nickname: f(globalState.user.nickname),
		age: f(globalState.user.age),
		prefecture_id: f(globalState.user.prefecture_id),
		greeting: globalState.user.greeting || '',
		icon_img: globalState.user.icon_img ?? undefined
	});
	const validationSchema = object({
		user_type: number(),
		nickname: string()
			.required('ニックネームを入力してください。')
			.max(USER.MAX_LENGTH.NICKNAME),
		name: string()
			.required('お名前を入力してください。')
			.max(USER.MAX_LENGTH.NAME),
		// icon_img: string().required('お名前を入力してください。'),
		prefecture_id: string().required(),
		age: number()
			.typeError('年齢を数字で入力してください')
			.required('年齢を入力してください。')
			.when('user_type', {
				is: 1,
				then: number().max(USER.MAX_LENGTH.AGE, '20歳以上の方は登録できません。')
			}),
		greeting: string().max(USER.MAX_LENGTH.GREETING)
	});
	const form = useForm({ validationSchema, initialValues });
	useField('nickname');
	useField('name');
	useField('icon_img');
	useField('age');
	useField('greeting');
	useField('prefecture_id');
	useField('user_type');
	return form;
};

const useCropperModal = () => {
	const state = reactive({
		isOpen: false,
		/** @type {File|null} */
		data: null
	});

	return {
		state,
		/**
		 * @param {Event} $event
		 */
		openModal($event) {
			const eventTarget = /** @type {HTMLInputElement} */ ($event.target);
			if (!eventTarget.files?.[0]) return;
			state.isOpen = true;
			state.data = eventTarget.files[0];
			// clear input to make input file can choose previous image again when close input file modal
			// eslint-disable-next-line no-param-reassign
			eventTarget.value = '';
		},
		closeModal() {
			state.isOpen = false;
			state.data = null;
		}
	};
};

export default {
	components: { CropModal },

	/** @type {{ closeEditModal: () => void }}  */
	// @ts-ignore
	emits: ['closeEditModal'],
	setup(props, context) {
		const state = reactive({
			isSubmitting: false
		});
		const form = useEditMyPageForm();

		const cropperModal = useCropperModal();
		const onCropImage = (/** @type {string} */ croppedImageBase64) => {
			form.values.icon_img = croppedImageBase64;
			cropperModal.closeModal();
		};
		const editMypageAction = async () => {
			if (!(await form.validate()).valid || state.isSubmitting) return;
			try {
				// TODO fix image upload logic (current is string data url)
				state.isSubmitting = true;
				const ngWords = await ngWordApi.getAll();
				const checkNotContainNgWord = ngWords.every(word => !f(form.values.nickname).includes(word.ng_word));
				if (!checkNotContainNgWord) {
					form.setErrors({
						nickname: 'ニックネームに禁止ワードが含まれているため、変更できません。'
					});
					return;
				}
				const checkGreetingNotContainNgWord = ngWords.every(word => !f(form.values.greeting).includes(word.ng_word));
				if (!checkGreetingNotContainNgWord) {
					form.setErrors({
						greeting: '挨拶文に禁止ワードが含まれているため、変更できません。'
					});
					return;
				}
				// eslint-disable-next-line camelcase
				const iconImg = form.values.icon_img;
				// eslint-disable-next-line camelcase
				// if (typeof icon_img !== 'string') {
				// 	if (icon_img.type !== 'image/png' && icon_img.type !== 'image/jpeg') {
				// 		form.setErrors({
				// 			icon_img: 'JPGまたはPNG形式の画像をアップロードしてください。'
				// 		});
				// 		return;
				// 	}
				// 	if (icon_img.size > 1) {
				// 		form.setErrors({ icon_img: '5MB未満の画像をアップロードしてください。' });
				// 		return;
				// 	}
				// }
				const downloadUrl =
					form.values.icon_img !== undefined
						? await storageApi.uploadAvatarImg({
								userId: f(globalState.user.id),
								file: f(form.values.icon_img)
						  })
						: null;
				const userData = {
					nickname: f(form.values.nickname),
					name: f(form.values.name),
					prefecture_id: f(form.values.prefecture_id),
					age: Number(form.values.age),
					greeting: form.values.greeting,
					icon_img: downloadUrl
				};
				await userApi.update(globalState.user.id, userData);
				globalActions.updateUserProfile(userData);
				/** @type {t.VueQuery.Query['user']['Key']} */
				const queryKey = ['user', { user_id: globalState.user.id }];
				queryClient.invalidateQueries(queryKey);
				context.emit('closeEditModal');
			} catch (error) {
				console.log(error);
			} finally {
				state.isSubmitting = false;
			}
		};

		const { data: prefectureOptions } = usePrefectures();

		return {
			globalActions,
			values: form.values,
			errors: form.errors,
			editMypageAction,
			USER,
			prefectureOptions,
			cropperModal,
			onCropImage
		};
	},
	computed: {
		/** @returns {string} */
		avatarPreviewImg() {
			const img = this.values.icon_img;
			if (!img) return defaultAvatarImg;
			if (typeof img === 'string') return img;
			return URL.createObjectURL(img);
		}
	}
};
</script>

<template>
	<div class="fixed top-0 left-0 right-0 w-full h-full bg-gray-500 bg-opacity-50 overlay z-50">
		<div class="flex items-center justify-center w-screen mx-auto pt-40px pb-40px">
			<div class="container text-22px ">
				<div class="flex flex-col h-screen mx-auto bg-white border-4 border-gray-800 rounded-5xl lg:pl-10 lg:pt-32px lg:pb-28px lg:w-8/12 modal-mypage">
					<div class="overflow-y-auto ">
						<div class="flex flex-row items-center justify-between p-4 ">
							<div class="flex flex-row ">
								<button type="button" class="w-6 h-6 mr-4 focus:outline-none" @click="$emit('closeEditModal')">
									<img src="@/assets/images/close-black-icon.png" class="sm:w-full w-4" alt="close icon" />
								</button>
								<div class="text-base font-bold sm:text-xl lg:text-30px flex flex-col sm:flex-row items-start sm:items-center w-167px sm:w-auto">
									<p>プロフィールを編集する（保護者の方と一覧に入力してね）</p>
									<div class="tooltip">
										<img src="@/assets/images/heart-white-icon.png" alt="heart" class="w-30px" />
										<span class="tooltiptext">（保護者の方と一覧に入力してね）</span>
									</div>
								</div>
							</div>
							<button
								type="button"
								class="p-2 text-xs font-bold text-white rounded-full sm:text-base sm:px-6 sm:py-3 lg:text-xl bg-asp-orange lg:min-w-224px focus:outline-none lg:hidden"
								@click="editMypageAction"
							>
								保存する
							</button>
						</div>
						<div class="flex flex-col px-8  pt-8 lg:mb-2 lg:flex-row lg:px-10 lg:mb-5">
							<div class="flex flex-col flex-1 order-2 md:order-2">
								<div class="w-full mb-10px">
									<label class="block text-xs font-bold sm:text-base mb-5px lg:text-22px">ニックネーム (公開されます)</label>
									<input
										v-model="values.nickname"
										class="w-full px-3 py-2 text-xs border border-gray-800 rounded focus:outline-none sm:text-base"
										type="text"
										maxlength="10"
										placeholder="公開しても良いニックネームを入力してね"
									/>
									<div class="mt-1 text-lg text-red-500">
										{{ errors.nickname }}
									</div>
								</div>
								<div class="w-full mb-10px">
									<label class="block text-xs font-bold sm:text-base mb-5px lg:text-22px">お名前 (公開されません)</label>
									<input
										v-model="values.name"
										class="w-full px-3 py-2 text-xs border border-gray-800 rounded focus:outline-none sm:text-base"
										type="text"
										maxlength="20"
										placeholder="お名前を入力してね"
									/>
									<div class="mt-1 text-lg text-red-500">
										{{ errors.name }}
									</div>
								</div>
								<div class="w-full mb-10px">
									<label class="block text-xs font-bold sm:text-base mb-5px lg:text-22px">年齢 (公開されません)</label>
									<input
										v-model="values.age"
										v-number="{ min: 1, max: 999 }"
										class="w-full px-3 py-2 mb-1 text-xs border border-gray-800 rounded focus:outline-none sm:text-base"
										type="number"
										placeholder="20 歳以上の方は登録できません。"
										@keyup="values.age = $event.target.value"
									/>
									<div class="mb-1 text-xs text-red-500 sm:text-base lg:text-lg">
										{{ errors.age }}
									</div>
									<p class="text-sm">
										20 歳以上の方はサポーターとなります。<br />
										ホームページ(リンク)よりご登録お願いします。
									</p>
								</div>
								<div class="mb-4">
									<label class=" lg:mt-4.8 block xl:text-sm xl:mb-1.25 sm:mb-2 lg:text-28px sm:text-base text-xs mb-5px ">
										<!-- eslint-disable-next-line no-irregular-whitespace -->
										住所(都道府県) （公開されません）</label
									>
									<div className="border flex border-gray-800 flex-1">
										<select
											v-model="values.prefecture_id"
											className="bg-right-top bg-no-repeat bg-contain bg-asp-light bg-dropdown-blue-square cursor-pointer text-asp-light-gray lg:h-51px text-align-last-center w-full p-2 pr-46px lg:text-22px sm:text-base text-xs appearance-none focus:outline-none"
										>
											<option v-for="option in prefectureOptions" :key="option.docId" :value="option.docId">
												{{ option.prefecture_name }}
											</option>
										</select>
									</div>
								</div>
							</div>
							<div class="flex flex-row items-center justify-between order-1 w-full mb-4 sm:flex-col lg:ml-16 lg:w-52 md:order-2 lg:mb-0">
								<div class="h-auto sm:mb-23px w-120px lg:w-full relative">
									<img :src="avatarPreviewImg" alt="user avatar" class="object-cover w-full h-full rounded-full" />
									<!-- <img v-if="globalState.user.user_type === 2" src="@/assets/images/supporter.png" alt="supporter" />
									<img v-if="globalState.user.user_type === 3" src="@/assets/images/special-supporter.png" alt="supporter" /> -->
								</div>
								<div>
									<label class="p-2 text-xs font-bold text-white rounded-full cursor-pointer sm:text-base sm:px-6 sm:py-3 bg-asp-orange lg:text-22px focus:outline-none">
										<input type="file" class="hidden" accept="image/jpeg, image/png" @change="cropperModal.openModal($event)" />
										写真を変更する
									</label>
								</div>
								<div class="mt-1 text-lg text-red-500">
									{{ errors.icon_img }}
								</div>
							</div>
						</div>

						<div class="flex flex-col px-8 pb-8">
							<label class="block text-xs font-bold sm:text-base mb-5px lg:text-22px">
								挨拶文：
							</label>
							<textarea
								v-model="values.greeting"
								class="h-40 px-3 py-2 border border-gray-800 rounded resize-none focus:outline-none text-xs lg:text-base"
								placeholder="挨拶文を入力してね "
								:maxlength="USER.MAX_LENGTH.GREETING"
							></textarea>
							<div
								:class="{
									'text-red-500': values.greeting.length >= USER.MAX_LENGTH.GREETING
								}"
								class="text-xs lg:text-base"
							>
								{{ values.greeting.length }}/{{ USER.MAX_LENGTH.GREETING }}
							</div>
							<div class="text-red-500">{{ errors.greeting }}</div>
						</div>
						<div class="hidden mb-8 text-center lg:block">
							<button type="button" class="px-6 py-3 text-xl font-bold text-white rounded-full bg-asp-orange min-w-224px focus:outline-none" @click="editMypageAction">
								保存する
							</button>
						</div>
					</div>
				</div>
			</div>
		</div>
	</div>
	<crop-modal v-if="cropperModal.state.isOpen" :file="cropperModal.state.data" @closeModal="cropperModal.closeModal" @onCropImage="onCropImage"> </crop-modal>
</template>

<style scoped>
.scrollbar-side::-webkit-scrollbar {
	width: 6px;
}
.scrollbar-side::-webkit-scrollbar-track {
	background: #f1f1f1;
}
.scrollbar-side::-webkit-scrollbar-thumb {
	background: #cccccc;
}
.scrollbar-side::-webkit-scrollbar-thumb:hover {
	background: rgb(168, 168, 168);
}
.modal-mypage {
	max-height: calc(100vh - 80px);
	width: calc(100% - 2rem);
}
.c-scrollbar-wide {
	overflow: auto;
	--scrollbar-track: initial;
	--scrollbar-thumb: initial;
	scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
	overflow: overlay;
}

.c-scrollbar-wide::-webkit-scrollbar-track {
	background-color: var(--scrollbar-track);
}

.c-scrollbar-wide::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-thumb);
}

.c-scrollbar-wide {
	scrollbar-width: auto;
}

.c-scrollbar-wide::-webkit-scrollbar {
	width: 16px;
	height: 16px;
}

.c-scrollbar-wide::-webkit-scrollbar-thumb {
	border-radius: 9999px;
}

.c-scrollbar-wide {
	--scrollbar-track: #d1d5db;
	--scrollbar-thumb: #9ca3af;
}

.c-scrollbar-wide::-webkit-scrollbar-thumb:hover {
	--scrollbar-thumb: #6b7280;
}

.c-scrollbar {
	overflow: auto;
	--scrollbar-track: initial;
	--scrollbar-thumb: initial;
	scrollbar-color: var(--scrollbar-thumb) var(--scrollbar-track);
	overflow: overlay;
}

.c-scrollbar::-webkit-scrollbar-track {
	background-color: var(--scrollbar-track);
}

.c-scrollbar::-webkit-scrollbar-thumb {
	background-color: var(--scrollbar-thumb);
}

.c-scrollbar {
	scrollbar-width: thin;
}

.c-scrollbar::-webkit-scrollbar {
	width: 8px;
	height: 8px;
}

.c-scrollbar::-webkit-scrollbar-thumb {
	border-radius: 9999px;
}

.c-scrollbar {
	--scrollbar-track: #d1d5db;
	--scrollbar-thumb: #9ca3af;
}

.c-scrollbar::-webkit-scrollbar-thumb:hover {
	--scrollbar-thumb: #6b7280;
}
.avatar-ribbon {
	top: 50%;
	left: 50%;
	transform: translate(-50%, -50%);
	width: 100%;
}
.tooltip {
	position: relative;
}
.tooltip .tooltiptext {
	visibility: hidden;
	width: 225px;
	background-color: #555;
	color: #fff;
	text-align: center;
	border-radius: 6px;
	padding: 5px 0;
	position: absolute;
	z-index: 1;
	bottom: -50px;
	margin-left: -60px;
	opacity: 0;
	transition: opacity 0.3s;
	font-size: 14px;
	right: 0;
}
/* .tooltip .tooltiptext::after {
	content: "";
	position: absolute;
	bottom: 100%;
	left: 50%;
	margin-left: -5px;
	border-width: 5px;
	border-style: solid;
	border-color: #555 transparent transparent transparent;
} */
.tooltip:hover .tooltiptext {
	visibility: visible;
	opacity: 1;
}
@media only screen and (max-width: 600px) {
	.tooltip .tooltiptext {
		right: -188px;
	}
}
</style>
