<script>
import { Cropper, CircleStencil } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import { onUnmounted } from 'vue';
import Navigation from './Navigation.vue';

export default {
	components: {
		Cropper,
		// eslint-disable-next-line vue/no-unused-components
		CircleStencil
	},
	props: ['file'],
	emits: ['closeModal', 'onCropImage'],
	setup(props) {
		// load image
		const blobUrl = URL.createObjectURL(props.file);
		onUnmounted(() => {
			URL.revokeObjectURL(blobUrl);
		});
		return {
			blobUrl
		};
	},
	data() {
		return {
			zoom: 0
		};
	},
	methods: {
		defaultSize({ imageSize }) {
			return {
				width: Math.min(imageSize.height, imageSize.width),
				height: Math.min(imageSize.height, imageSize.width)
			};
		},
		stencilSize({ boundaries }) {
			return {
				width: Math.min(boundaries.height, boundaries.width) - 48,
				height: Math.min(boundaries.height, boundaries.width) - 48
			};
		},
		onChange(result) {
			const { cropper } = this.$refs;
			if (cropper) {
				if (cropper.imageSize.height < cropper.imageSize.width) {
					this.zoom = (cropper.imageSize.height - cropper.coordinates.height) / (cropper.imageSize.height - cropper.sizeRestrictions.minHeight);
				} else {
					this.zoom = (cropper.imageSize.width - cropper.coordinates.width) / (cropper.imageSize.width - cropper.sizeRestrictions.minWidth);
				}
			}
		},

		onZoom(value) {
			const { cropper } = this.$refs;
			if (cropper) {
				if (cropper.imageSize.height < cropper.imageSize.width) {
					const { minHeight } = cropper.sizeRestrictions;
					cropper.zoom(((1 - this.zoom) * cropper.imageSize.height + minHeight) / ((1 - value) * cropper.imageSize.height + minHeight));
				} else {
					const { minWidth } = cropper.sizeRestrictions;
					cropper.zoom(((1 - this.zoom) * cropper.imageSize.width + minWidth) / ((1 - value) * cropper.imageSize.width + minWidth));
				}
			}
		}
	}
};
</script>

<template>
	<div class="fixed top-0 left-0 z-50 w-screen h-screen max-h-screen px-4 bg-dark-transparent-600">
		<div class="flex flex-col items-center">
			<cropper
				ref="cropper"
				class="image-cropper"
				:stencil-props="{
					aspectRatio: 1
				}"
				:default-size="{
					width: 999999999,
					height: 999999999
				}"
				:src="blobUrl"
				@change="onChange"
			/>
			<!-- <div class="p-4">
				<navigation :zoom="zoom" @change="onZoom" />
			</div> -->
		</div>
		<div class="flex flex-row justify-center p-4">
			<button type="button" class="w-32 py-2 rounded-full border-3 border-asp-blue bg-asp-9FA0A0 focus:outline-none" @click="$emit('closeModal')">
				<span class="text-base font-bold lg:text-xl">キャンセル</span>
			</button>
			<button
				type="button"
				class="w-32 py-2 ml-6 rounded-full border-3 border-asp-blue bg-asp-yellow focus:outline-none"
				@click="$emit('onCropImage', $refs.cropper.getResult().canvas.toDataURL('image/jpeg'))"
			>
				<span class="items-center text-base text-xl font-bold">保存</span>
			</button>
		</div>
	</div>
</template>

<style lang="scss">
// .twitter-cropper {
// 	&__background {
// 		background-color: #edf2f4;
// 	}
// 	&__foreground {
// 		background-color: #edf2f4;
// 	}
// 	&__stencil {
// 		border: solid 5px rgb(29, 161, 242);
// 		border-radius: 50%;
// 	}
// }
.image-cropper {
	height: calc(100vh - 10rem);
}
</style>
